import axios from 'axios'

const production = false
const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});



const AUTH_TOKEN = localStorage.getItem('AUTH_TOKEN')
api.defaults.headers.common['Authorization'] = "Bearer "+ AUTH_TOKEN;
// api.defaults.headers.common['Content-Type'] = "application/json";
console.log("process.env.VUE_APP_API_URL" + process.env.VUE_APP_API_URL);
export { api }
