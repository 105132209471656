<template>
  <v-navigation-drawer
    width="100%"
    height="calc(100% - 2rem)"
    app
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    class="my-4 ms-4 border-radius-xl"
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
  >

    <hr
        class="horizontal mb-0"
        :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
    />

    <div v-for="(item, i) in superAdminItems" :key="item.title+i" >
      <v-list-item
          link
          :to="item.link"
          class="pb-1 mx-2 no-default-hover py-2"
          :ripple="false"
          active-class="item-active"
          v-if="!item.external"
      >
        <v-list-item-icon class="me-2 align-center">
          <i class="material-icons-round opacity-10">{{ item.action }}</i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
              class="ms-1"
          >{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
          link
          :to="item.link"
          class="pb-1 mx-2 no-default-hover py-2"
          :ripple="false"
          active-class="item-active"
          v-if="item.external"
      >
        <v-list-item-icon class="me-2 align-center">
          <i class="material-icons-round opacity-10">{{ item.action }}</i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
              class="ms-1"
          >{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
    <hr
        class="horizontal mb-0"
        :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
    />
    <v-card
      class="pa-0 border-radius-lg mt-7 mb-9 mx-4"
      :style="`background-image: url(${require('../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
    >
      <span
        class="mask opacity-8 border-radius-lg"
        :class="`bg-gradient-` + sidebarColor"
      ></span>
    </v-card>
  </v-navigation-drawer>
</template>
<script>

export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "dark",
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    superAdminItems: [
      {
        action: "dashboard",
        link: "/",
        title: "dashBoard",
        external: false,
      },
      {
        action: "upload",
        link: "/importer",
        title: "excelImport",
        external: false,
      },
      {
        action: "local_mall",
        link: "/products",
        title: "products",
        external: false,
      },
      {
        action: "shop",
        link: "/uploadedProducts",
        title: "shopwareProducts",
        external: false,
      },
    ],
  }),
  async created() {

  },
  methods: {
    // listClose(event) {
    //   let items;
    //   let headers;
    //   let groups;
    //   let currentEl;
    //
    //   if (
    //     document.querySelectorAll(
    //       ".mb-0.v-list-item.v-list-item--link.item-active"
    //     )
    //   ) {
    //     items = document.querySelectorAll(
    //       ".mb-0.v-list-item.v-list-item--link.item-active"
    //     );
    //   }
    //
    //   if (
    //     document.querySelectorAll(
    //       ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
    //     )
    //   ) {
    //     headers = document.querySelectorAll(
    //       ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
    //     );
    //   }
    //
    //   if (
    //     document.querySelectorAll(
    //       ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
    //     )
    //   ) {
    //     groups = document.querySelectorAll(
    //       ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
    //     );
    //   }
    //
    //   if (
    //     event.target.closest(
    //       ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
    //     )
    //   ) {
    //     currentEl = event.target.closest(
    //       ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
    //     );
    //   }
    //
    //   if (items != null) {
    //     for (var i = 0; i < items.length; i++) {
    //       items[i].classList.remove("item-active");
    //     }
    //   }
    //
    //   if (headers != null) {
    //     for (var j = 0; j < headers.length; j++) {
    //       headers[j].classList.remove(
    //         "v-list-item--active",
    //         "item-active",
    //         "primary--text"
    //       );
    //       headers[j].setAttribute("aria-expanded", false);
    //     }
    //   }
    //
    //   if (groups != null) {
    //     for (var k = 0; k < groups.length; k++) {
    //       groups[k].classList.remove("v-list-group--active", "primary--text");
    //     }
    //   }
    //
    //   if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
    //     event.target
    //       .closest(".mb-0.v-list-item.v-list-item--link")
    //       .classList.add("item-active");
    //   }
    //
    //   if (currentEl != null) {
    //     currentEl
    //       .querySelector(".v-list-group__header")
    //       .classList.add("v-list-item--active", "item-active");
    //   }
    // },
  },
};
</script>
