<template>
  <v-menu
      transition="slide-y-transition"
      offset-y
      offset-x
      min-width="300"
      max-width="300"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          outlined
          rounded
          icon
          x-small
          :ripple="false"
          class="text-body mx-2"
          v-bind="attrs"
          v-on="on"
          small
          @click="setLang"
          height="20px"
          width="28px"
      >
        {{language}}
      </v-btn>
    </template>
  </v-menu>

</template>

<script>
export default {
  name: "LangView",

  data() {
    return {
      lang: localStorage.getItem('LANG_TOKEN'),
      language: "",
    }
  },
  async created() {
    if (localStorage.getItem('LANG_TOKEN') === 'de'){
      this.language = 'EN'
    }else if (localStorage.getItem('LANG_TOKEN') === 'en'){
      this.language = 'DE'
    }else{
      this.language = 'EN'
    }
  },
  methods: {
    setLang() {
      if (localStorage.getItem('LANG_TOKEN') === 'de') {
        localStorage.setItem('LANG_TOKEN', 'en')
        this.language = 'DE'
      } else {
        localStorage.setItem('LANG_TOKEN', 'de')
        this.language = 'EN'
      }
      this.$router.go()
    }
  }
}
</script>

<style scoped>

</style>