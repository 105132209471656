import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import { i18n} from "@/main";

// Dashboard pages
const Importer = () => import("../views/Importer.vue");
const Dashboard = () => import("../views/Dashboard.vue");
const Login = () => import("../views/Login.vue");
const Products = () => import("../views/Products.vue");
const UploadedProducts = () => import("../views/UploadedProducts");
const Settings = () => import("../views/settings");



Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    name: "Login",
    redirect: "/",
    component: AuthBasicLayout,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: {
          groupName: "Login",
        },
      },
    ]
  },
  {
    path: "/",
    name: "",
    redirect: "/",
    component: DashboardLayout,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiredAuth: true,
          groupName: "Dashboard",
        },
      },
      {
        path: "importer",
        name: "excelImport",
        component: Importer,
        meta: {
          groupName: "Dashboard",
        },
      },
      {
        path: "products",
        name: "products",
        component: Products,
        meta: {
          groupName: "Dashboard",
        },
      },
      {
        path: "uploadedProducts",
        name: "shopwareProducts",
        component: UploadedProducts,
        meta: {
          groupName: "Dashboard",
        },
      },
      {
        path: "settings",
        name: "settings",
        component: Settings,
        meta: {
          groupName: "Dashboard",
        },
      },
    ],
  },
];


const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  //Is der Lang_Token gesetzt
  if (localStorage.getItem('LANG_TOKEN')) {
    try {
      i18n.locale = localStorage.getItem('LANG_TOKEN')
      next()
    } catch (e) {
      console.log(e)
      localStorage.setItem('LANG_TOKEN', 'de')
      next()
    }
  } else {
    localStorage.setItem('LANG_TOKEN', 'de')
    next()
  }
})

export default router;
