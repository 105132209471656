<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        class="position-relative min-vh-100 ql-bg-red"
        :style="`background-image:  url('https://source.unsplash.com/random/1250x1250/?office,tea'); background-size: cover; background-position: 50%;`"
      >
        <span class="mask bg-gradient-default opacity-6"></span>
        <v-container class="my-auto">
          <v-row class="min-vh-100">
            <v-app-bar
                absolute
                :color="'transparent'"
                height="auto"
                class="py-4 px-2 toolbar-content-padding-y-none"
                flat
            >
              <v-container>
                <v-row>
                  <v-col cols="3" class="d-flex align-center">
                    <p class="font-weight-bolder ms-lg-0 ms-4 mb-0 text-white text-sm">
                      <img src="../../../src/assets/img/logos/ls&m.png" style="height: 100%; width: 100%;max-height: 4rem"/>
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-app-bar>
            <fade-transition :duration="200" origin="center top" mode="out-in">
              <!-- your content here -->
              <v-container class="pb-0 position-relative">
                <router-view></router-view>
              </v-container>
            </fade-transition>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AppBarAuth from "@/components/AppBarAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    AppBarAuth,
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {
      paragraphs: "",
      tab: null,
    };
  },
  methods: {
    headerTitle() {
      switch (this.$route.name) {
        case "SignUpBasic":
          this.paragraphs =
            "Use these awesome forms to login or create new account in your project for free.";
          return "Welcome!";
        default:
          break;
      }
    },
  },
};
</script>
